Vue.component('pricebox', {
  template: `
  <div class="product-price-container">
    <span class="product-price-addition">
        {{ label }}
    </span>
    <span class="product-price">
        <span class="product-old-price" v-if="renderOldPrice">
            <price :price="renderOldPrice" :currency="currency" />
        </span>
        <span class="product-current-price">
            <price :price="renderPrice" :currency="currency" />
        </span>
    </span>
  </div>
  `,
  store: ConfStore,
  props: [
    'price',
    'oldprice',
    'label',
    'currency'
  ],
  computed: {
    hideEndPrice: function() {
      return this.$store.state.hideEndPrice;
    },
    renderPrice: function() {
      if(this.oldprice && this.hideEndPrice) {
        return this.oldprice;
      } else {
        return this.price;
      }
    },
    renderOldPrice: function() {
      if(this.oldprice && !this.hideEndPrice) {
        return this.oldprice;
      } else {
        return false;
      }
    }
  }
})